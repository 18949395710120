
var date = new Date();
var hour = date.getHours();
var body = document.getElementsByTagName('body')[0];
var gradient;

if(hour >= 0 && hour <= 4) {
    gradient = "linear-gradient(to bottom, black, purple)"
}

if(hour > 4 && hour <= 6) {
    gradient = "linear-gradient(to bottom, purple, orange)"
}

if(hour > 6 && hour <= 12) {
    gradient = "linear-gradient(to bottom, orange, blue)"
}

if(hour > 12 && hour <= 18) {
    gradient = "linear-gradient(to bottom, blue, orange)"
}

if(hour > 18 && hour <= 20) {
    gradient = "linear-gradient(to bottom, orange, purple)"
}

if(hour > 20 && hour <= 24) {
    gradient = "linear-gradient(to bottom, purple, black)"
}

body.style.backgroundImage = gradient;

var greeting;

if (hour >= 0 && hour < 12) {
    greeting = "Good Morning";
} else if (hour >= 12 && hour < 18) {
    greeting = "Good Afternoon";
} else {
    greeting = "Good Evening";
}

var greetingDiv = document.getElementsByClassName('greeting')[0];
greetingDiv.innerHTML = greeting;



      